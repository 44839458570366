import { strapiInstance } from "@/providers/StrapiProvider";
import { selector } from "recoil";

const getWorkspaceGroups = selector({
  key: "get-workspace-groups",
  get: async () => {
    return (await strapiInstance.find("workspace-groups"))?.data || [];
  },
});

export const getWorkspaceGroupsOptions = selector({
  key: "get-workspace-groups-options",
  get: ({ get }) => {
    const groups = get(getWorkspaceGroups);
    return groups.map(({ id, name }) => ({
      label: name as string,
      value: id as number,
    }));
  },
});
