import React, { useCallback, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { Box, Button } from "@mui/material";
import { User } from "@/modules/strapi-sdk/lib/interfaces";
import { usersInsightQuery, UserType, userTypeLabel } from "@/store/user";
import { useNavigate } from "@repleek/mui";

type Props = {
  active: User["type"];
};

const Navigations: React.FC<Props> = (props) => {
  const { active } = props;
  const insights = useRecoilValue(usersInsightQuery);
  const { navigate } = useNavigate();

  const typeCount = useMemo(() => {
    let counter: {
      label: string;
      count: number;
      type: UserType;
    }[] = [];
    if (insights?.type) {
      const stats = insights.type;
      counter = Object.entries(stats).map(([type, count]) => ({
        label: userTypeLabel[type as UserType]?.label,
        count,
        type: type as UserType,
      }));
    }

    return counter;
  }, [insights?.type]);

  const onChange = useCallback(
    (params: { type: UserType | null }) => {
      navigate?.(params?.type ? `/users/${params.type}` : "/users");
    },
    [navigate]
  );

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: "8px",
        flexWrap: "wrap",
      }}
    >
      {typeCount.map(({ count, label, type }) => (
        <Button
          key={type}
          sx={{
            boxShadow: "none",
            padding: "4px 4px 4px 8px",
            fontSize: "11px",
            textWrap: "nowrap",
          }}
          variant={type === active ? "contained" : "outlined"}
          color="secondary"
          onClick={() => onChange({ type: type === active ? null : type })}
        >
          {label}
          <Box
            component="span"
            sx={{
              ml: 1,
              border: "1px solid black",
              padding: "0 6px",
              borderRadius: "16px",
              background: "black",
              color: "white",
            }}
          >
            {count}
          </Box>
        </Button>
      ))}
    </Box>
  );
};

export default Navigations;
