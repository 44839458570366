import { Primitive } from "@/interfaces";
import {
  PaginationByPage,
  StrapiBaseRequestParams,
} from "@/modules/strapi-sdk";
import { ContentType } from "@/modules/strapi-sdk/lib/api";
import { Account, User } from "@/modules/strapi-sdk/lib/interfaces";
import { strapiInstance } from "@/providers/StrapiProvider";
import { atomFamily, selectorFamily } from "recoil";

type Query = {
  workspaces: { accountId: number };
  "workspace-groups": {};
  accounts: {};
  leads: {};
  "me/accounts": {
    type: Account["type"][];
    workspaces: number[];
    leads: [boolean];
  };
  "me/users": {
    type: User["type"][];
    workspaces: number[];
  };
  messages: {};
  notifications: {};
  posts: {};
  sheets: {};
  "social-reports": {};
  "social-statistics": {};
};

type StrapiQuery<T extends keyof ContentType> = {
  pagination: PaginationByPage;
  search?: string;
} & Query[T] &
  StrapiBaseRequestParams<ContentType[T]>;

export const collectionQuery = atomFamily<
  StrapiQuery<keyof ContentType>,
  { collection: keyof ContentType; id: string }
>({
  key: "collection-query",
  default: {
    pagination: {
      page: 1,
      pageSize: 9,
      withCount: true,
    },
  },
});

export const getCollectionQueryCount = selectorFamily({
  key: "collection-query-count",
  get:
    <T extends keyof ContentType>(params: { collection: T; id: string }) =>
    ({ get }) => {
      const { collection, id } = params;
      const { pagination, search, ...filters } = get(
        collectionQuery({ collection, id })
      ) as any;

      return { filters: Object.keys(filters).length };
    },
});

const fetchCollection = async <T extends keyof ContentType>(
  collection: T,
  params: StrapiQuery<T>
) => {
  let filters: any = {};
  let fields: any = ["id"];
  let populate: any = {};

  if (collection === "me/accounts") {
    const { search, type, workspaces, leads } =
      params as StrapiQuery<"me/accounts">;
    fields = ["id", "name", "email", "type", "url", "workspace"];
    filters = {
      ...(search && {
        $or: [
          { name: { $containsi: search } },
          { email: { $containsi: search } },
        ],
      }),
      type: { $in: type },
      workspace: { id: workspaces },
      ...(leads?.length && {
        sheet: { $null: !leads[0] },
      }),
    };
    populate = { workspace: true };
  }

  if (collection === "workspaces") {
    const { search } = params as StrapiQuery<"workspaces">;

    fields = ["id", "name"];
    filters = {
      ...(search && {
        $or: [
          {
            name: { $containsi: search },
          },
        ],
      }),
    };
    populate = {
      avatar: true,
    };
  }

  if (collection === "me/users") {
    const { search, type, workspaces } = params as StrapiQuery<"me/users">;
    //fields = ["id", "name"];
    filters = {
      ...(search && {
        $or: [
          { firstName: { $containsi: search } },
          { email: { $containsi: search } },
        ],
      }),
      type: { $in: type },
      workspaces: { id: workspaces },
    };
    populate = {
      avatar: true,
      workspaces: true,
    };
  }

  return await strapiInstance.find(collection, {
    pagination: params.pagination,
    filters,
    fields,
    sort: ["createdAt:DESC"],
    populate,
  });
};

export const collectionListSelector = selectorFamily({
  key: "collectionListSelector",
  get:
    <T extends keyof ContentType>(params: {
      collection: T;
      id: string;
      defaultFilters?: Record<string, Primitive | Primitive[]>;
    }) =>
    async ({ get }) => {
      const { collection, id, defaultFilters } = params;
      const query = get(collectionQuery({ collection, id })) as any;

      return await fetchCollection(collection, { ...query, ...defaultFilters });
    },
});
