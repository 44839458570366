import { useCallback, useEffect, useState } from "react";
import { Post } from "@/modules/strapi-sdk/lib/interfaces";
import { useStrapi } from "@/providers/StrapiProvider";

type Param = { id?: string; accountId?: string; onError?: () => void };

export const usePost = (params: Param) => {
  const { accountId, id, onError } = params;

  const [post, setPost] = useState<Post | undefined>(undefined);
  const [strapi] = useStrapi();

  const fetch = useCallback(() => {
    if (id && accountId)
      strapi
        ?.findOne("posts", id, {
          populate: {
            account: true,
            socialProfileIs: true,
            video: { populate: "*" },
            media: true,
            messages: { fields: ["id", "status"] },
            errors: true,
            hootsuite_messages: true,
          },
        })
        .then((res) => {
          if (res?.account?.id === parseInt(accountId as string)) setPost(res);
          else {
            setPost(undefined);
            onError?.();
          }
        })
        .catch(() => onError?.());
    else setPost(undefined);
  }, [accountId, id]);

  useEffect(() => {
    fetch();
  }, [fetch, id, accountId]);

  return [post, { fetch, setPost }] as const;
};
