/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
  IconProps,
  InputBase,
  InputBaseProps,
} from "@repleek/mui";
import { FaPlus } from "react-icons/fa6";
import { IoClose, IoFilter, IoSearchOutline } from "react-icons/io5";
import { RiRefreshLine } from "react-icons/ri";
// #endregion

// #region Type Definition
type CollectionsGridProps = Partial<ContainerProps> & {
  items: Partial<BoxProps>,
  pagination: Partial<BoxProps>,
  newBtn: ButtonProps & {},
  filterBtn: Partial<ButtonProps>,
  searchField: Partial<InputBaseProps>,
  filterBox: Partial<BoxProps>,
  clearSearch: Partial<IconProps>,
  refreshBtn: Partial<ButtonProps>,
  headerBox: Partial<BoxProps>,
};
// #endregion

// #region CollectionsGridComponent
const CollectionsGrid: React.FC<CollectionsGridProps> = (props) => {
  const {
    items,
    pagination,
    newBtn,
    filterBtn,
    searchField,
    filterBox,
    clearSearch,
    refreshBtn,
    headerBox,
    ...rootProps
  } = props;

  return (
    <Container
      maxWidth={"md"}
      disableGutters={false}
      fixed={false}
      css={{
        paddingTop: "8px",
        paddingBottom: "8px",
        gap: "12px",
        display: "flex",
        flexDirection: "column",
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        className={"bg-white"}
        css={{
          boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          borderRadius: "16px",
          paddingLeft: "16px",
          paddingRight: "16px",
          marginBottom: "8px",
          paddingTop: "8px",
          paddingBottom: "8px",
          ".mui-dark &": {
            boxShadow:
              "rgba(255, 252, 252, 0.02) 0px 1px 3px 0px  , rgba(255, 255, 255, 0.15) 0px 0px 0px 1px ",
          },
        }}
      >
        <Box
          tag={"div"}
          css={{ paddingTop: "8px", paddingBottom: "8px" }}
          {...headerBox}
        />
        <Box
          tag={"div"}
          css={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            tag={"div"}
            css={{
              minWidth: "248px",
              boxShadow:
                "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
              borderRadius: "8px",
              paddingLeft: "8px",
              paddingRight: "8px",
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            <Icon
              color={"inherit"}
              fontSize={"inherit"}
              icon={IoSearchOutline}
            />
            <InputBase
              inputName={"Input name"}
              color={"primary"}
              type={"text"}
              aria-label={"Input Base Mui"}
              placeholder={"Recherche ..."}
              autoFocus={true}
              disabled={false}
              fullWidth={true}
              maxRows={1}
              minRows={1}
              rows={1}
              multiline={false}
              readOnly={false}
              required={false}
              size={"medium"}
              css={{ paddingLeft: "8px", flexGrow: "1" }}
              {...searchField}
            />
            <Icon
              color={"inherit"}
              fontSize={"inherit"}
              icon={IoClose}
              css={{ cursor: "pointer" }}
              {...clearSearch}
            />
          </Box>
          <Box
            tag={"div"}
            css={{
              gap: "8px",
              paddingTop: "8px",
              paddingBottom: "8px",
              display: "inline-flex",
            }}
          >
            <Button
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              iconStart={true}
              iconEnd={false}
              className={"icon-btn filter-btn"}
              css={{ paddingLeft: "12px", paddingRight: "12px" }}
              IconStart={
                <Icon color={"inherit"} fontSize={"inherit"} icon={IoFilter} />
              }
              {...filterBtn}
            />
            <Button
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              iconStart={true}
              iconEnd={false}
              className={"icon-btn"}
              css={{
                padding: "0px",
                paddingLeft: "12px",
                paddingRight: "12px",
              }}
              IconStart={
                <Icon
                  color={"inherit"}
                  fontSize={"inherit"}
                  icon={RiRefreshLine}
                />
              }
              {...refreshBtn}
            />
            <Button
              text={"Nouveau"}
              type={"button"}
              disabled={false}
              disableElevation={false}
              disableFocusRipple={false}
              disableRipple={false}
              fullWidth={false}
              size={"small"}
              variant={"outlined"}
              color={"secondary"}
              iconStart={true}
              iconEnd={false}
              css={{ paddingLeft: "12px", paddingRight: "12px" }}
              IconStart={
                <Icon
                  color={"inherit"}
                  fontSize={"inherit"}
                  icon={FaPlus}
                  css={{ marginRight: "8px" }}
                />
              }
              {...newBtn}
            />
          </Box>
        </Box>
      </Box>
      <Box tag={"div"} {...filterBox} />
      <Box tag={"div"} css={{ minHeight: "477px" }}>
        <Box tag={"div"} css={{ flexGrow: "1" }} {...items} />
      </Box>
      <Box
        tag={"div"}
        css={{ marginTop: "8px", display: "flex", justifyContent: "center" }}
        {...pagination}
      />
    </Container>
  );
};

export default CollectionsGrid;
// #endregion
