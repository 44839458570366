export function decodeHtml(html?: string) {
  if (!html) return;
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}
export const ssoOauth = {
  tiktok: {
    url: `${process.env.REACT_APP_API_URL}/api/sso/tiktok`,
    title: "Tiktok",
  },
  facebook: {
    url: `${process.env.REACT_APP_API_URL}/api/sso/facebook`,
    title: "",
  },
  instagram: {
    url: `${process.env.REACT_APP_API_URL}/api/sso/facebook`,
    title: "",
  },
  linkedin: {
    url: `${process.env.REACT_APP_API_URL}/api/sso/linkedin`,
    title: "",
  },
  hootsuite: {
    url: `${process.env.REACT_APP_API_URL}/api/sso/hootsuite`,
    title: "",
  },
  google: {
    url: `${process.env.REACT_APP_API_URL}/api/sso/google`,
    title: "",
  },
};

export const chunkArray = <T>(array: T[], size: number): T[][] => {
  const chunks = [];
  for (let i = 0; i < array.length; i += size) {
    chunks.push(array.slice(i, i + size));
  }
  return chunks;
};

type Entries<T> = {
  [K in keyof T]: [K, T[K]];
}[keyof T][];

export function typedEntries<T>(obj: T): Entries<T> {
  return Object.entries(obj as any) as Entries<T>;
}

export const urlHttps = (url?: string) => {
  if (!url) return undefined;
  // Vérifie si l'URL commence déjà par "https://"
  if (!/^https?:\/\//i.test(url)) {
    // Si ce n'est pas le cas, ajoute "https://"
    return "https://" + url;
  }
  // Si l'URL commence déjà par "http://" ou "https://", retourne l'URL telle quelle
  return url;
};
