import React, { useEffect, useMemo, useRef, useState } from "react";
import { useRecoilValue } from "recoil";
import { BriefContainer, BriefField, BriefText } from "@/components";
import { getAccountBrief, Field } from "@/store/accounts";
import { useThrottle } from "@uidotdev/usehooks";
import { Box, Checkbox } from "@mui/material";
import { FaRegCircleDot } from "react-icons/fa6";
import { LuCalendarCheck2 } from "react-icons/lu";

type Props = { id: number };

const Brief: React.FC<Props> = (props) => {
  const { id } = props;
  const brief = useRecoilValue(getAccountBrief(id));
  const [search, setSearch] = useState("");
  const throttledSearch = useThrottle(search, 800);
  const ref = useRef<HTMLInputElement>(null);

  const fields = useMemo(() => {
    const search = throttledSearch.toLocaleLowerCase();
    return brief?.filter(
      ({ label, value, type }) =>
        label?.toLowerCase().includes(search) ||
        (type === "text" && value?.includes(search))
    );
  }, [brief, throttledSearch]);

  useEffect(() => {
    if (ref.current) {
      ref.current.value = "";
    }
  }, []);

  return (
    <BriefContainer
      search={{
        onKeyUp: (event) => setSearch(event.currentTarget.value),
        ref,
      }}
      items={{
        children: fields.map((field) => <Item key={field.label} {...field} />),
      }}
    />
  );
};

export default Brief;

const Item: React.FC<Field> = (props) => {
  switch (props.type) {
    case "text":
      return (
        <BriefText
          label={{ text: props.label }}
          value={{ text: props.value || "" }}
        />
      );

    case "select":
      return (
        <BriefField
          label={{ text: props.label }}
          value={{
            children: props.value?.map((v) => (
              <Box
                key={v}
                sx={{ display: "flex", alignItems: "center", gap: "8px" }}
              >
                <FaRegCircleDot />
                <span>{v}</span>
              </Box>
            )),
          }}
        />
      );

    case "checkbox":
      return (
        <BriefField
          label={{ text: props.label }}
          value={{
            children: (
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Checkbox checked={!!props.value} />
                <span>{props.value ? "Oui" : "Non"}</span>
              </Box>
            ),
          }}
        />
      );

    case "date":
      return (
        <BriefField
          label={{ text: props.label }}
          value={{
            children: (
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <LuCalendarCheck2 />
                <span>{props.value?.start || "Non précisé"}</span>
              </Box>
            ),
          }}
        />
      );
  }
};
