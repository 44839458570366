import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, MenuItem, Select, TextField, Typography } from "@mui/material";
import { HiOutlineUserCircle } from "react-icons/hi2";
import { Modal, PistacheLoader } from "@/components/layout";
import { LeadPreview } from "@/components";
import { SelectChangeEvent } from "@mui/material/Select/SelectInput";
import { useStrapi } from "@/providers/StrapiProvider";
import { Lead } from "@/modules/strapi-sdk/lib/interfaces";
import { leadStatus } from "@/store/leads";

type Porps = {
  id?: number;
  onClose: () => void;
  refresh?: () => void;
};

const LeadModal: React.FC<Porps> = (props) => {
  const { onClose, id, refresh } = props;

  const [lead, setLead] = useState<Lead | undefined>(undefined);
  const [description, setDescription] = useState<string>();

  const [strapi, { loading }] = useStrapi();

  const properties = useMemo(() => {
    if (!lead?.properties) return [];
    const propertiesHide = ["adset", "campagne", "objectif", "plateforme"];
    return Object.entries(lead.properties as Record<string, string>)
      .filter(([label]) => !propertiesHide.includes(label.toLowerCase()))
      .map(([label, value]) => ({
        label,
        value,
      }));
  }, [lead?.properties]);

  const fetchLead = useCallback(() => {
    if (id) {
      strapi?.findOne("leads", id).then((lead) => {
        setLead(lead);
        setDescription(lead.description);
      });
    }
  }, [id, strapi]);

  const onStatusChange = useCallback(
    async (event: SelectChangeEvent) => {
      const status = event.target.value as any;
      if (lead?.id) {
        await strapi?.update("leads", lead?.id, { status });
        fetchLead();
        refresh?.();
      }
    },
    [fetchLead, lead?.id, refresh, strapi]
  );

  const onCall = useCallback(() => {
    window.location.href = "tel:" + lead?.phone;
  }, [lead?.phone]);

  const handleClose = useCallback(async () => {
    onClose();
    if (description !== lead?.description && lead?.id) {
      await strapi?.update("leads", lead.id, { description });
      refresh?.();
    }
  }, [description, lead?.description, lead?.id, onClose, refresh, strapi]);

  useEffect(() => {
    fetchLead();
    return () => setLead(undefined);
  }, [fetchLead]);

  return (
    <Modal
      open={!!id}
      type="box"
      onClose={handleClose}
      icon={{ icon: HiOutlineUserCircle }}
      title={lead?.name}
      noActions
    >
      {loading || !lead ? (
        <PistacheLoader />
      ) : (
        <LeadPreview
          phone={{ text: lead?.phone }}
          city={{ text: lead?.city }}
          cityBox={{ hidden: !lead?.city }}
          email={{ text: lead?.email }}
          status={{
            children: (
              <Select
                fullWidth
                size="small"
                onChange={onStatusChange}
                value={lead?.status}
              >
                {Object.entries(leadStatus).map(([value, label]) => (
                  <MenuItem value={value} key={value}>
                    {label}
                  </MenuItem>
                ))}
              </Select>
            ),
          }}
          properties={{
            hidden: !lead?.properties,
            children: (
              <>
                {lead && (
                  <TextField
                    rows={3}
                    fullWidth
                    label="Commentaire"
                    size="small"
                    multiline
                    value={description}
                    onChange={(e) => setDescription(e.currentTarget.value)}
                  />
                )}
                {properties.map(({ label, value }) => (
                  <Box
                    sx={{
                      padding: "4px 8px",
                      borderBottom: "1px solid #d0ced0",
                    }}
                    key={label}
                  >
                    <Typography variant="overline" sx={{ fontWeight: 900 }}>
                      {label}
                    </Typography>
                    <Typography sx={{ pl: 1 }}>{value}</Typography>
                  </Box>
                ))}
              </>
            ),
          }}
          call={{ onClick: onCall }}
        />
      )}
    </Modal>
  );
};

export default LeadModal;
