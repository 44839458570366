/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Container,
  ContainerProps,
  Icon,
  InputBase,
  InputBaseProps,
} from "@repleek/mui";
import { IoSearchOutline } from "react-icons/io5";
// #endregion

// #region Type Definition
type BriefContainerProps = Partial<ContainerProps> & {
  items: Partial<BoxProps>,
  search: Partial<InputBaseProps>,
};
// #endregion

// #region BriefContainerComponent
const BriefContainer: React.FC<BriefContainerProps> = (props) => {
  const { items, search, ...rootProps } = props;

  return (
    <Container
      maxWidth={"sm"}
      disableGutters={false}
      fixed={false}
      {...rootProps}
    >
      <Box tag={"div"}>
        <Box
          tag={"div"}
          css={{
            boxShadow:
              "rgba(0, 0, 0, 0.05) 0px 6px 24px 0px , rgba(0, 0, 0, 0.08) 0px 0px 0px 1px",
            paddingLeft: "12px",
            paddingRight: "12px",
            paddingTop: "4px",
            paddingBottom: "4px",
            borderRadius: "8px",
            gap: "8px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Icon color={"inherit"} fontSize={"inherit"} icon={IoSearchOutline} />
          <InputBase
            inputName={"Input name"}
            color={"primary"}
            type={"text"}
            aria-label={"Input Base Mui"}
            placeholder={"Recherche ..."}
            autoFocus={false}
            disabled={false}
            fullWidth={true}
            maxRows={1}
            minRows={1}
            rows={1}
            multiline={false}
            readOnly={false}
            required={false}
            size={"medium"}
            {...search}
          />
        </Box>
      </Box>
      <Box
        tag={"div"}
        css={{
          maxHeight: "calc(100vh - 460px)",
          overflowY: "auto",
          paddingLeft: "8px",
          paddingRight: "8px",
          paddingTop: "16px",
          paddingBottom: "8px",
        }}
        {...items}
      />
    </Container>
  );
};

export default BriefContainer;
// #endregion
