import React, { useCallback, useMemo, useRef } from "react";
import { NotionDatabaseMapper } from "@/modules/strapi-sdk/lib/interfaces";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { NotionItem, NotionPageSelect } from "@/components";
import { NotionDbType, notionTablesQuery } from "@/store/notion";
import { DatabaseObjectResponse } from "@/interfaces/notion-sdk";
import { useStrapi } from "@/providers/StrapiProvider";

type Props = {
  type: NotionDbType;
  search: string;
  value?: NotionDatabaseMapper;
  wkGroupId: number;
  onSearch: (search?: string) => void;
  onClose: () => void;
  setValue: (value: NotionDatabaseMapper) => void;
};

const NotionTableList: React.FC<Props> = (props) => {
  const { type, onSearch, onClose, setValue, search, value, wkGroupId } = props;

  const ref = useRef<any>(null);
  const [strapi] = useStrapi();

  const { results } = useRecoilValue(
    notionTablesQuery({
      q: search,
      wkGroupId,
    })
  );
  const refresh = useRecoilRefresher_UNSTABLE(
    notionTablesQuery({ q: search, wkGroupId })
  );

  const clearSearch = useCallback(() => {
    if (ref.current) ref.current.value = "";
    onSearch(undefined);
  }, [onSearch]);

  const formatedResults = useMemo(
    () =>
      results
        .filter(({ archived, in_trash }) => !archived || !in_trash)
        .map(({ id, title, properties, icon, url }) => {
          let avatarUrl = "";
          switch (icon?.type) {
            case "external":
              avatarUrl = icon.external?.url;
              break;
            case "file":
              avatarUrl = icon.file.url;
              break;
          }
          return {
            id,
            name: title?.[0]?.plain_text,
            avatarUrl,
            properties,
            url,
          };
        }),
    [results]
  );

  const onSelectItem = useCallback(
    async (data: {
      id: string;
      name: string;
      avatarUrl: string;
      url: string;
      properties: DatabaseObjectResponse["properties"];
    }) => {
      if (data.id === value?.database_id) return onClose();

      const response = await strapi?.post(
        "notion/databases-mapper",
        {},
        {
          id: data.id,
          name: data.name,
          properties: data.properties,
          type,
        }
      );
      if (response) {
        setValue({
          ...value,
          mapping: response.attributes,
          name: response.name,
          notion_url: data.url,
          database_id: response.id,
        });
        onClose();
      }
    },
    [onClose, setValue, strapi, type, value]
  );

  return (
    <NotionPageSelect
      list={{
        children: formatedResults?.map((page) => (
          <NotionItem
            key={page.id}
            onClick={() => onSelectItem(page)}
            avatar={{
              src: page.avatarUrl,
              initial: page.name?.substring(0, 2),
            }}
            name={{ text: page.name }}
            selected={{
              hidden: page.id !== value?.database_id,
            }}
            email={{ hidden: true }}
          />
        )),
      }}
      search={{
        inputName: "search",
        onKeyUp: (event) => onSearch(event.currentTarget.value),
        ref,
        defaultValue: search,
      }}
      refresh={{ onClick: refresh }}
      clear={{ onClick: clearSearch, hidden: !search }}
    />
  );
};

export default NotionTableList;
