import React from "react";
import { Box, Skeleton } from "@mui/material";

const NavSkeleton: React.FC = () => {
  return (
    <Box sx={{ width: "100%", display: "flex", gap: "8px" }}>
      {[1, 2, 3, 4, 5].map((v) => (
        <Skeleton
          variant="rounded"
          width={130}
          height={33}
          key={v}
          sx={{ borderRadius: "36px" }}
        />
      ))}
    </Box>
  );
};

export default NavSkeleton;
