import { DateRangeValue } from "@/components/fields/DateRangePicker";
import { Lead } from "@/modules/strapi-sdk/lib/interfaces";
import { LeadInsight } from "@/modules/strapi-sdk/lib/type";
import { strapiInstance } from "@/providers/StrapiProvider";
import dayjs from "dayjs";
import { atomFamily, selector, selectorFamily } from "recoil";

export type LeadsParams = {
  category: string;
  status?: string | null;
  range?: DateRangeValue;
};

export const formatDateRangeValue = (range?: DateRangeValue) => {
  if (!range || !Array.isArray(range)) return undefined;
  const [from, to] = range;
  if (!from || !to) return undefined;

  return { date: { $gte: dayjs(from).format(), $lte: dayjs(to).format() } };
};

export const sheetList = selector({
  key: "sheet-list",
  get: async () => {
    return (
      (
        await strapiInstance.find("sheets", {
          pagination: { page: 0, pageSize: 200 },
          filters: {
            account: {
              $null: true,
            },
          },
        })
      )?.data || []
    );
  },
});

export const leadsParamsQuery = atomFamily<LeadsParams, number>({
  key: "leads-params-query",
  default: {
    category: "ALL",
  },
});

export const leadInsightQuery = selectorFamily({
  key: "lead-insights-query",
  get:
    (accountId: number | undefined) =>
    async ({ get }) => {
      if (!accountId) return undefined;
      const { range } = get(leadsParamsQuery(accountId));
      return await strapiInstance.get(
        "leads/insights/:accountId",
        {
          ":accountId": accountId,
        },
        formatDateRangeValue(range)
      );
    },
});

export const leadById = selectorFamily({
  key: "lead-by-id",
  get: (id?: number) => async () => {
    if (!id) return undefined;
    return await strapiInstance.findOne("leads", id);
  },
});

export const leadStatus: Record<string, string> = {
  new: "Nouveau",
  processed_no_followup: "Traité - sans suite",
  processed_signed: "Traité - signé",
  no_response: "Ne répond pas",
  to_process: "À traiter",
};

export const leadInsightByReportingId = selectorFamily({
  key: "lead-insight-by-reporting-id",
  get: (id: number) => async () => {
    const leads = await strapiInstance.get(
      "social-reports/:id/leads-insights",
      {
        ":id": id,
      }
    );

    if (!leads?.length) return [];

    return leads.reduce((acc, { date, ...rest }) => {
      const mounth = dayjs(date).format("MM");
      if (!acc[mounth]) acc[mounth] = rest;
      else {
        Object.entries(rest).forEach(([status, value]) => {
          acc[mounth][status as Lead["status"]] += value;
        });
      }
      return acc;
    }, {} as Record<string, Omit<LeadInsight, "date">>);
  },
});
