import React from "react";
import { Chip, Icon } from "@repleek/mui";
import { GoDotFill } from "react-icons/go";

type Props = {
  label: string;
  color?: "default" | "brown" | "orange" | "green" | "blue" | "red" | "purple";
  variant?: "filled" | "outlined";
};

const Badge: React.FC<Props> = (props) => {
  const { label, color = "default", variant = "outlined" } = props;

  return (
    <Chip
      label={label}
      avatar={false}
      icon={true}
      clickable={false}
      color={"default"}
      variant={variant}
      css={{ "@media (max-width: 700px)": { display: "none" } }}
      Icon={
        <Icon
          color={"inherit"}
          fontSize={"inherit"}
          icon={GoDotFill}
          className={"MuiChip-icon MuiChip-iconMedium MuiChip-iconColorDefault"}
        />
      }
      size="small"
      className={color}
    />
  );
};

export default Badge;
