import { useParams } from "react-router-dom";
import React, { Suspense } from "react";
import Layout, { PistacheLoader } from "@/components/layout";
import UsersPage from "../workspaces/workspace/users";
import { User } from "@/modules/strapi-sdk/lib/interfaces";
import Navigations from "./Navigations";
import NavSkeleton from "./NavSkeleton";

const Users: React.FC = () => {
  return (
    <Layout title="Pistache - utilisateurs" mode="ADMIN">
      <Suspense fallback={<PistacheLoader />}>
        <Component />
      </Suspense>
    </Layout>
  );
};

const Component: React.FC = () => {
  const { type } = useParams() as {
    type: User["type"];
  };

  return (
    <UsersPage
      id={`${type || "all"}-users`}
      type={type}
      headerContent={
        <Suspense fallback={<NavSkeleton />}>
          <Navigations active={type} />
        </Suspense>
      }
    />
  );
};

export default Users;
