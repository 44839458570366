import React, { useEffect, useMemo, useRef, useState } from "react";
import { useThrottle } from "@uidotdev/usehooks";
import { useRecoilRefresher_UNSTABLE, useRecoilValue } from "recoil";
import { NotionItem, NotionPageSelect } from "@/components";
import { usersList } from "@/store/user";

type Props = {
  selectedUsers: number[];
  onLink: (id: number) => void;
  wkId: number;
};

const UserModalList: React.FC<Props> = (props) => {
  const { selectedUsers, onLink, wkId } = props;

  const [search, setSearch] = useState("");
  const throttledSearch = useThrottle(search, 800);
  const ref = useRef<HTMLInputElement>(null);

  const users = useRecoilValue(usersList({ workspaces: [wkId] }));

  const _users = useMemo(
    () =>
      users
        .map(({ id, email, firstName, lastName, avatar }) => ({
          id,
          index: [email, firstName, lastName].join("|").toLowerCase(),
          initial: `${firstName?.[0] || email[0]}${lastName?.[0] || email[1]}`,
          name: [firstName, lastName].filter((v) => v).join(" "),
          avatar: avatar?.url,
          email,
        }))
        .filter(({ id }) => id && !selectedUsers.includes(id)),
    [selectedUsers, users]
  );
  const refresh = useRecoilRefresher_UNSTABLE(usersList({}));

  const usersFilterd = useMemo(
    () =>
      _users?.filter(({ index }) =>
        index.includes(throttledSearch.toLocaleLowerCase())
      ),
    [_users, throttledSearch]
  );

  useEffect(() => {
    if (ref.current) {
      ref.current.value = "";
    }
  }, []);

  return (
    <NotionPageSelect
      list={{
        children: usersFilterd?.map(({ id, avatar, name, initial, email }) => (
          <NotionItem
            key={id}
            onClick={() => id && onLink(id)}
            avatar={{
              src: avatar,
              initial,
            }}
            name={{ text: name }}
            selected={{
              hidden: true,
            }}
            email={{ text: email }}
          />
        )),
      }}
      search={{
        inputName: "search",
        onKeyUp: (event) => setSearch(event.currentTarget.value),
        ref,
      }}
      refresh={{ onClick: refresh }}
      clear={{}}
    />
  );
};

export default UserModalList;
