/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Container,
  ContainerProps,
  Icon,
  SelectField,
  SelectFieldProps,
  TextField,
} from "@repleek/mui";
import { RiRefreshLine } from "react-icons/ri";
// #endregion

// #region Type Definition
type WorkspaceFormFieldProps = Partial<ContainerProps> & {
  back: ButtonProps & {},
  modal: Partial<BoxProps>,
  syncBtn: Partial<ButtonProps>,
  tablesConfig: Partial<BoxProps>,
  groupeSelect: Partial<SelectFieldProps>,
};
// #endregion

// #region WorkspaceFormFieldComponent
const WorkspaceFormField: React.FC<WorkspaceFormFieldProps> = (props) => {
  const { back, modal, syncBtn, tablesConfig, groupeSelect, ...rootProps } =
    props;

  return (
    <Container
      maxWidth={"sm"}
      disableGutters={false}
      fixed={false}
      css={{ fontSize: "12px", paddingTop: "16px" }}
      {...rootProps}
    >
      <Box tag={"div"} css={{ minWidth: "482px", marginBottom: "16px" }}>
        <Box
          tag={"div"}
          css={{
            gap: "8px",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <TextField
            inputName={"name"}
            label={"Nom du workspace"}
            variant={"outlined"}
            color={"secondary"}
            type={"text"}
            aria-label={"Input Base Mui"}
            autoFocus={false}
            disabled={false}
            fullWidth={true}
            maxRows={1}
            minRows={1}
            rows={1}
            multiline={false}
            readOnly={false}
            required={true}
            size={"small"}
          />
          <SelectField
            options={[{ label: "Default", value: "default" }]}
            inputName={"workspace_group.id"}
            label={"Groupe"}
            variant={"outlined"}
            color={"primary"}
            aria-label={"Input Base Mui"}
            autoFocus={false}
            disabled={false}
            fullWidth={true}
            multiple={false}
            readOnly={false}
            required={true}
            size={"small"}
            {...groupeSelect}
          />
          <Button
            type={"button"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            size={"medium"}
            variant={"outlined"}
            iconStart={true}
            iconEnd={false}
            className={"icon-btn"}
            css={{
              fontSize: "12px",
              paddingLeft: "14px",
              paddingRight: "14px",
              paddingTop: "8px",
              paddingBottom: "8px",
            }}
            IconStart={
              <Icon
                color={"inherit"}
                fontSize={"inherit"}
                icon={RiRefreshLine}
              />
            }
            {...syncBtn}
          />
        </Box>
        <Box tag={"div"} {...tablesConfig} />
        <Box tag={"div"} {...modal} />
        <Box
          tag={"div"}
          className={"footer"}
          css={{
            marginTop: "16px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            text={"Retour"}
            type={"button"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            color={"secondary"}
            iconStart={false}
            iconEnd={false}
            {...back}
          />
          <Button
            text={"Enregistrer"}
            type={"submit"}
            disabled={false}
            disableElevation={false}
            disableFocusRipple={false}
            disableRipple={false}
            fullWidth={false}
            variant={"contained"}
            iconStart={false}
            iconEnd={false}
          />
        </Box>
      </Box>
    </Container>
  );
};

export default WorkspaceFormField;
// #endregion
