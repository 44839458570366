// Module dependencies & types
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from "axios";
import defu from "defu";
import qs from "qs";
import Cookies from "js-cookie";
import { cleanDoubleSlashes, joinURL } from "ufo";

// Load custom types
import type {
  StrapiAuthenticationData,
  StrapiAuthenticationResponse,
  StrapiAuthProvider,
  StrapiBaseRequestParams,
  StrapiDefaultOptions,
  StrapiEmailConfirmationData,
  StrapiError,
  StrapiForgotPasswordData,
  StrapiOptions,
  StrapiRegistrationData,
  StrapiRequestParams,
  StrapiResetPasswordData,
  StrapiResponse,
  StrapiUser,
  StrapiChangePasswordData,
} from "./type";

// Load utils methods
import { isBrowser, replaceStringWithParams } from "./utils";
import { ContentType, CustomEndPoint } from "./api";
import { toast } from "react-toastify";

// Strapi options' default values
const defaults: StrapiDefaultOptions = {
  url: "http://localhost:1337",
  prefix: "/api",
  store: {
    key: "strapi_jwt",
    useLocalStorage: false,
    cookieOptions: { path: "/" },
  },
  axiosOptions: {},
  events: {
    onAuthChange: () => {},
  },
};

export class Strapi {
  public axios: AxiosInstance;
  public options: StrapiDefaultOptions;
  public onLoading?: (loading: boolean) => void;
  private user: StrapiUser = null;

  /**
   * Strapi SDK Constructor
   *
   * @constructor
   * @param {StrapiOptions} options? - Options in order to configure API URL, list your Content Types & extend the axios configuration
   * @param {string} options.url? - Your Strapi API URL, Default: http://localhost::1337
   * @param {StoreConfig} options.store? - Config the way you want to store JWT (Cookie or LocalStorage)
   * @param {AxiosRequestConfig} options.axiosOptions? - The list of your Content type on your Strapi API
   */
  constructor(options?: StrapiOptions) {
    // merge given options with default values
    const _options = defu(options || {}, defaults);

    // clean url & prefix
    this.options = {
      ..._options,
      url: cleanDoubleSlashes(_options?.url),
      prefix: cleanDoubleSlashes(_options?.prefix),
    };

    // create axios instance
    this.axios = axios.create({
      baseURL: joinURL(this.options.url, this.options.prefix),
      paramsSerializer: qs.stringify,
      ...this.options.axiosOptions,
    });

    // Synchronize token before each request
    this.axios.interceptors.request.use((config) => {
      const token = this.getToken();
      if (token) {
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
      }

      return config;
    });
  }

  /**
   * Basic axios request
   *
   * @param  {Method} method - HTTP method
   * @param  {string} url - Custom or Strapi API URL
   * @param  {AxiosRequestConfig} axiosConfig? - Custom Axios config
   * @returns Promise<T>
   */
  public async request<T>(
    method: Method,
    url: string,
    axiosConfig?: AxiosRequestConfig
  ): Promise<T | undefined> {
    try {
      this.onLoading?.(true);
      const response: AxiosResponse<T> = await this.axios.request<T>({
        method,
        url,
        ...axiosConfig,
      });
      return response?.data;
    } catch (error) {
      const e = error as AxiosError<StrapiError>;

      if (!e.response?.data?.error) {
        toast(e.message, { type: "error" });
      } else {
        switch (e.response?.data?.error.status) {
          // handle 401 session end refresh token maybe
          case 401:
            this.logout();
            break;

          default:
            toast(e.response.data.error.message, { type: "error" });
            break;
        }
      }
    } finally {
      this.onLoading?.(false);
    }
  }
  /**
   * Authenticate user & retrieve his JWT
   *
   * @param  {StrapiAuthenticationData} data - User authentication form data: `identifier`, `password`
   * @param  {string} data.identifier - The email or username of the user
   * @param  {string} data.password - The password of the user
   * @returns Promise<StrapiAuthenticationResponse>
   */
  public async login(data: StrapiAuthenticationData) {
    this.removeToken();
    const res = await this.request<StrapiAuthenticationResponse>(
      "post",
      "/auth/local",
      { data }
    );
    if (res) {
      const { jwt } = res;
      this.setToken(jwt);
      this.user = await this.fetchUser();
      this.options.events.onAuthChange?.(this.user);
      this.request("get", "/auth/trace");
      return { user: this.user, jwt };
    }
  }

  /**
   * Register a new user & retrieve JWT
   *
   * @param  {StrapiRegistrationData} data - New user registration data: `username`, `email`, `password`
   * @param  {string} data.username - Username of the new user
   * @param  {string} data.email - Email of the new user
   * @param  {string} data.password - Password of the new user
   * @returns Promise<StrapiAuthenticationResponse>
   */
  public async register(data: StrapiRegistrationData) {
    this.removeToken();
    const res = await this.request<StrapiAuthenticationResponse>(
      "post",
      "/auth/local/register",
      { data }
    );
    if (res) {
      const { user, jwt } = res;
      this.setToken(jwt);
      this.user = user;
      this.options.events.onAuthChange?.(user);
      return { user, jwt };
    }
  }

  /**
   * Send an email to a user in order to reset his password
   *
   * @param  {StrapiForgotPasswordData} data - Forgot password data: `email`
   * @param  {string} data.email - Email of the user who forgot his password
   * @returns Promise<void>
   */
  public async forgotPassword(data: StrapiForgotPasswordData) {
    this.removeToken();
    return this.request<boolean>("post", "/auth/forgot-password", { data });
  }

  /**
   * Change the password of the logged in user.
   * @author AnnikenYT
   *
   * @param {StrapiChangePasswordData} data - Change password data: `currentPassword`, `password`, `passwordConfirmation`
   * @param {string} data.currentPassword - The current password of the user
   * @param {string} data.password - The new password of the user
   * @param {string} data.passwordConfirmation - Confirmation of the new password of the user
   * @returns Promise<StrapiAuthenticationResponse>
   */
  public async changePassword(data: StrapiChangePasswordData) {
    const res = await this.request<StrapiAuthenticationResponse>(
      "post",
      "/auth/change-password",
      { data }
    );
    if (res) {
      const { user, jwt } = res;
      this.setToken(jwt);
      this.user = user;
      this.options.events.onAuthChange?.(user);
      return { jwt, user };
    }
  }

  /**
   * Reset the user password
   *
   * @param  {StrapiResetPasswordData} data - Reset password data object: `code`, `password`, `passwordConfirmation`
   * @param  {string} data.code - Code received by email after calling the `forgotPassword` method
   * @param  {string} data.password - New password of the user
   * @param  {string} data.passwordConfirmation - Confirmation of the new password of the user
   * @returns Promise<StrapiAuthenticationResponse>
   */
  public async resetPassword(data: StrapiResetPasswordData) {
    this.removeToken();
    const res = await this.request<StrapiAuthenticationResponse>(
      "post",
      "/auth/reset-password",
      { data }
    );
    if (res) {
      const { user, jwt } = res;
      this.setToken(jwt);
      this.user = user;
      this.options.events.onAuthChange?.(user);
      return { user, jwt };
    }
  }

  /**
   * Send programmatically an email to a user in order to confirm his account
   *
   * @param  {StrapiEmailConfirmationData} data - Email confirmation data: `email`
   * @param  {string} data.email - Email of the user who want to be confirmed
   * @returns Promise<void>
   */
  public async sendEmailConfirmation(
    data: StrapiEmailConfirmationData
  ): Promise<void> {
    return this.request("post", "/auth/send-email-confirmation", {
      data,
    });
  }
  /**
   * Get the correct URL to authenticate with provider
   *
   * @param  {StrapiAuthProvider} provider - Provider name
   * @returns string
   */
  public getProviderAuthenticationUrl(provider: StrapiAuthProvider): string {
    return joinURL(this.options.url, this.options.prefix, "connect", provider);
  }

  /**
   * Authenticate user with the token present on the URL or in `params`
   *
   * @param  {StrapiAuthProvider} provider - Provider name
   * @param  {string} access_token? - Access Token return from Strapi
   * @returns Promise<StrapiAuthenticationResponse>
   */
  public async authenticateProvider(
    provider: StrapiAuthProvider,
    access_token?: string
  ) {
    this.removeToken();
    if (isBrowser()) {
      const params = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      if (params.access_token) access_token = params.access_token as string;
    }
    const res = await this.request<StrapiAuthenticationResponse>(
      "get",
      `/auth/${provider}/callback`,
      {
        params: { access_token },
      }
    );
    if (res) {
      const { user, jwt } = res;
      this.setToken(jwt);
      this.user = user;
      this.options.events.onAuthChange?.(user);
      return { user, jwt };
    }
  }

  /**
   * Logout by removing authentication token
   *
   * @returns void
   */
  public logout(): void {
    this.user = null;
    this.options.events.onAuthChange?.(null);
    this.removeToken();
  }

  /**
   * Get a list of {content-type} entries
   *
   * @param  {string} contentType - Content type's name pluralized
   * @param  {StrapiRequestParams} params? - Query parameters
   * @returns Promise<StrapiResponse<T>>
   */
  public find<T extends keyof ContentType>(
    contentType: T,
    params?: StrapiRequestParams<ContentType[T]>
  ) {
    return this.request<StrapiResponse<ContentType[T][]>>(
      "get",
      `/${contentType}`,
      {
        params,
      }
    );
  }

  /**
   * Get a specific {content-type} entry
   *
   * @param  {string} contentType - Content type's name pluralized
   * @param  {string|number} id - ID of entry
   * @param  {StrapiBaseRequestParams} params? - Fields selection & Relations population
   * @returns Promise<StrapiResponse<T>>
   */
  public async findOne<T extends keyof ContentType>(
    contentType: T,
    id: string | number,
    params?: StrapiBaseRequestParams<ContentType[T]>
  ) {
    const res = await this.request<any>("get", `/${contentType}/${id}`, {
      params,
    });

    if (res && res.data && res.meta) return res.data as ContentType[T];

    return res as ContentType[T];
  }

  /**
   * Create a {content-type} entry
   *
   * @param  {string} contentType - Content type's name pluralized
   * @param  {AxiosRequestConfig["data"]} data - New entry
   * @param  {StrapiBaseRequestParams} params? - Fields selection & Relations population
   * @returns Promise<StrapiResponse<T>>
   */
  public create<T extends keyof ContentType>(
    contentType: T,
    data: Omit<ContentType[T], "id">,
    params?: StrapiBaseRequestParams<ContentType[T]>
  ) {
    return this.request<StrapiResponse<ContentType[T]>>(
      "post",
      `/${contentType}`,
      {
        data: { data },
        params,
      }
    );
  }

  /**
   * Update a specific entry
   *
   * @param  {string} contentType - Content type's name pluralized
   * @param  {string|number} id - ID of entry to be updated
   * @param  {AxiosRequestConfig["data"]} data - New entry data
   * @param  {StrapiBaseRequestParams} params? - Fields selection & Relations population
   * @returns Promise<StrapiResponse<T>>
   */
  public update<T extends keyof ContentType>(
    contentType: T,
    id: string | number,
    data: Partial<Omit<ContentType[T], "id">>,
    params?: StrapiBaseRequestParams<ContentType[T]>
  ) {
    return this.request<StrapiResponse<ContentType[T]>>(
      "put",
      `/${contentType}/${id}`,
      {
        data: { data },
        params,
      }
    );
  }

  /**
   * Delete en entry
   *
   * @param  {string} contentType - Content type's name pluralized
   * @param  {string|number} id - ID of entry to be deleted
   * @param  {StrapiBaseRequestParams} params? - Fields selection & Relations population
   * @returns Promise<StrapiResponse<T>>
   */
  public delete<T extends keyof ContentType>(
    contentType: T,
    id: string | number,
    params?: StrapiBaseRequestParams<ContentType[T]>
  ) {
    return this.request<StrapiResponse<ContentType[T]>>(
      "delete",
      `/${contentType}/${id}`,
      {
        params,
      }
    );
  }

  /**
   * Refresh local data of the logged-in user
   *
   * @returns Promise<StrapiUser>
   */
  public async fetchUser(): Promise<StrapiUser> {
    const token = this.getToken();
    if (token) {
      const user = await this.request<StrapiUser>("get", "/me");
      if (user) {
        this.user = user;
        this.options.events.onAuthChange?.(user);
      } else this.logout();
    }

    return this.user;
  }

  /**
   * Retrieve token from chosen storage
   *
   * @returns string | null
   */
  public getToken(): string | null {
    const { useLocalStorage, key } = this.options.store;
    if (isBrowser()) {
      const token = useLocalStorage
        ? window.localStorage.getItem(key)
        : (Cookies.get(key) as string);

      if (typeof token === "undefined") return null;

      return token;
    }

    return null;
  }

  /**
   * Set token in chosen storage
   *
   * @param  {string} token - Token retrieve from login or register method
   * @returns void
   */
  public setToken(token: string): void {
    const { useLocalStorage, key, cookieOptions } = this.options.store;
    if (isBrowser()) {
      useLocalStorage
        ? window.localStorage.setItem(key, token)
        : Cookies.set(key, token, cookieOptions);
    }
  }

  /**
   * Remove token from chosen storage (Cookies or Local)
   *
   * @returns void
   */
  public removeToken(): void {
    const { useLocalStorage, key } = this.options.store;
    if (isBrowser()) {
      useLocalStorage
        ? window.localStorage.removeItem(key)
        : Cookies.remove(key);
    }
  }

  public get<P extends keyof CustomEndPoint["GET"]>(
    path: P,
    params?: CustomEndPoint["GET"][P]["params"],
    data?: CustomEndPoint["GET"][P]["data"]
  ) {
    return this.request<CustomEndPoint["GET"][P]["result"]>(
      "get",
      replaceStringWithParams(path, params),
      {
        params: data,
      }
    );
  }

  public post<P extends keyof CustomEndPoint["POST"]>(
    path: P,
    params: CustomEndPoint["POST"][P]["params"],
    data: CustomEndPoint["POST"][P]["data"],
    axiosConfig?: AxiosRequestConfig
  ) {
    return this.request<CustomEndPoint["POST"][P]["result"]>(
      "post",
      replaceStringWithParams(path, params),
      {
        ...axiosConfig,
        data: { data },
      }
    );
  }
}
