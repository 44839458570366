import { Message } from "@/modules/strapi-sdk/lib/interfaces";
import { strapiInstance } from "@/providers/StrapiProvider";
import { selectorFamily } from "recoil";

type Params = {
  id: number;
  socialError?: Message["socialError"];
};

export const messagesByPost = selectorFamily({
  key: "messages-by-post",
  get:
    ({ id, socialError }: Params) =>
    async () => {
      const res = await strapiInstance.find("messages", {
        filters: {
          post: {
            id: { $eq: id },
          },
          socialError: socialError || null,
        },
        populate: {
          author: {
            fields: ["id", "firstName", "lastName", "email"],
          },
        },
      });

      return (
        res?.data?.map(({ text, author, readBy }) => ({
          text,
          seen: true,
          user: {
            id: author?.id?.toString() || "user",
            name:
              (author?.firstName || author?.lastName
                ? `${author.firstName} ${author.lastName}`
                : author?.email) || "Utilisateur",
          },
        })) || []
      );
    },
});
