import React, { Suspense, useCallback, useMemo, useState } from "react";
import { Modal, PistacheLoader } from "@/components/layout/modals";
import NotionTableList from "./NotionTableList";
import { useStrapi } from "@/providers/StrapiProvider";
import { NotionDbType } from "@/store/notion";
import { useDebounce } from "@/hooks/utility";
import { useFormContext, useWatch } from "react-hook-form";
import { Workspace } from "@/modules/strapi-sdk/lib/interfaces";

type Props = {
  type?: NotionDbType;
  onClose: () => void;
};

const searhValues: Record<NotionDbType, string> = {
  notion_account: "Clients",
  notion_notification_cr: "Notification",
  notion_notification_post: "Retour client",
  notion_post: "Livrables",
  norion_user: "Utilisateurs",
};

const NotionTableSelect: React.FC<Props> = (props) => {
  const { type, onClose } = props;

  const [, { loading }] = useStrapi();
  const [search, setSearch] = useState<string | undefined>(undefined);
  const onSearch = useDebounce(setSearch, 800);

  const _search = useMemo(
    () => search || (type && searhValues[type]) || "",
    [search, type]
  );
  const { setValue } = useFormContext();
  const workspace = useWatch() as Partial<Workspace>;
  const value = useMemo(
    () => (type ? workspace?.[type] : undefined),
    [type, workspace]
  );

  const close = useCallback(() => {
    onClose();
    setSearch("");
  }, [onClose]);

  return (
    <Modal open={!!type} onClose={close} noHeader type="form" noActions>
      <Suspense fallback={<PistacheLoader css={{ minWidth: "340px" }} />}>
        {loading ? (
          <PistacheLoader css={{ minWidth: "340px" }} />
        ) : (
          type &&
          workspace.workspace_group?.id && (
            <NotionTableList
              type={type}
              search={_search}
              onSearch={onSearch}
              onClose={close}
              setValue={(value) => setValue(type, value)}
              value={value}
              wkGroupId={workspace.workspace_group.id}
            />
          )
        )}
      </Suspense>
    </Modal>
  );
};

export default NotionTableSelect;
