import { User } from "@/modules/strapi-sdk/lib/interfaces";
import { strapiInstance } from "@/providers/StrapiProvider";
import { selector, selectorFamily } from "recoil";

export type UserType = NonNullable<User["type"]>;

type UserListParams = { workspaces?: number[]; search?: string };

export const userTypeLabel: Record<
  UserType,
  {
    label: string;
    color: "default" | "brown" | "orange" | "green" | "blue" | "red" | "purple";
  }
> = {
  customer: { label: "Client", color: "blue" },
  admin: { label: "Administrateur", color: "purple" },
  cm: { label: "Comunity M.", color: "green" },
  commercial: { label: "Commercial", color: "brown" },
};

export const userTypeOptions = Object.entries(userTypeLabel).map(
  ([value, { label }]) => ({ value, label })
);

export const usersList = selectorFamily({
  key: " users",
  get: (params: UserListParams) => async () => {
    const { workspaces, search } = params;

    const res = await strapiInstance.find("me/users", {
      filters: { ...(workspaces?.length && { workspaces }) },
      populate: { avatar: true },
      pagination: {
        page: 1,
        pageSize: 200,
      },
    });

    if (!res?.data) return [];

    return res.data;
  },
});

export const userById = selectorFamily({
  key: "user-by-id",
  get: (id: number | boolean) => async () => {
    if (typeof id !== "number") return undefined;

    return (
      await strapiInstance.find("me/users", {
        filters: {
          id,
        },
        populate: {
          avatar: true,
        },
      })
    )?.data?.[0];
  },
});

export const usersInsightQuery = selector({
  key: "users-insights-query",
  get: async () => await strapiInstance.get("/me/insights"),
});
