import { useMemo } from "react";
import { User } from "@/modules/strapi-sdk/lib/interfaces";
import { Box, Typography } from "@mui/material";
import { IconType } from "react-icons";
import dayjs from "dayjs";
import { FaUserClock } from "react-icons/fa";
import Badge from "../Badge";
import { userTypeLabel } from "@/store/user";

const Label: React.FC<{ icon: IconType; label: string }> = ({
  icon: Icon,
  label,
}) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", gap: "4px", mb: 0.2 }}>
      <Icon />
      <Typography fontSize="14px">{label}</Typography>
    </Box>
  );
};

export const UserBody: React.FC<Pick<User, "lastConnexion" | "type">> = ({
  lastConnexion,
  type,
}) => {
  const date = useMemo(
    () => (lastConnexion ? dayjs(lastConnexion).fromNow() : "Non connecté(e)"),
    [lastConnexion]
  );

  const role = useMemo(() => type && userTypeLabel[type], [type]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      {role && (
        <Badge label={role.label} color={role.color} variant="outlined" />
      )}
      <Label icon={FaUserClock} label={date} />
    </Box>
  );
};
