/** Build with repleek */
// #region Import
import React from "react";

import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Icon,
  Typography,
  TypographyProps,
} from "@repleek/mui";
import { MdAlternateEmail, MdInfo, MdPhone } from "react-icons/md";
import { HiLocationMarker } from "react-icons/hi";
// #endregion

// #region Type Definition
type LeadPreviewProps = Partial<BoxProps> & {
  phone: TypographyProps & {},
  city: TypographyProps & {},
  cityBox: Partial<BoxProps>,
  email: TypographyProps & {},
  properties: Partial<BoxProps>,
  call: ButtonProps & {},
  status: Partial<BoxProps>,
};
// #endregion

// #region LeadPreviewComponent
const LeadPreview: React.FC<LeadPreviewProps> = (props) => {
  const {
    phone,
    city,
    cityBox,
    email,
    properties,
    call,
    status,
    ...rootProps
  } = props;

  return (
    <Box
      tag={"div"}
      css={{
        minWidth: "380px",
        maxWidth: "396px",
        paddingTop: "16px",
        paddingBottom: "16px",
        "@media (max-width: 700px)": { minWidth: "280px" },
      }}
      {...rootProps}
    >
      <Box
        tag={"div"}
        css={{
          padding: "8px",
          gap: "8px",
          borderBottomWidth: "1px",
          borderBottomColor: "rgb(208, 206, 208)",
          borderBottomStyle: "solid",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon
          color={"inherit"}
          fontSize={"inherit"}
          icon={MdPhone}
          css={{ fontSize: "22px" }}
        />
        <Typography
          variant={"overline"}
          text={"Téléphone"}
          gutterBottom={false}
          noWrap={false}
          css={{ flexGrow: "1" }}
        />
        <Typography
          variant={"body1"}
          text={"<b>Mon titre ici</b>"}
          gutterBottom={false}
          noWrap={false}
          {...phone}
        />
        <Button
          text={"Appeler"}
          type={"button"}
          disabled={false}
          disableElevation={false}
          disableFocusRipple={false}
          disableRipple={false}
          fullWidth={false}
          size={"small"}
          variant={"contained"}
          iconStart={false}
          iconEnd={false}
          css={{
            fontSize: "12px",
            paddingLeft: "8px",
            paddingRight: "8px",
            paddingTop: "4px",
            paddingBottom: "4px",
          }}
          {...call}
        />
      </Box>
      <Box
        tag={"div"}
        css={{
          padding: "8px",
          gap: "8px",
          borderBottomWidth: "1px",
          borderBottomColor: "rgba(208, 206, 208, 1)",
          borderBottomStyle: "solid",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon
          color={"inherit"}
          fontSize={"inherit"}
          icon={MdAlternateEmail}
          css={{ fontSize: "22px" }}
        />
        <Typography
          variant={"overline"}
          text={"Email"}
          gutterBottom={false}
          noWrap={false}
          css={{ flexGrow: "1" }}
        />
        <Typography
          variant={"body1"}
          text={"<b>Mon titre ici</b>"}
          gutterBottom={false}
          noWrap={false}
          {...email}
        />
      </Box>
      <Box
        tag={"div"}
        css={{
          padding: "8px",
          gap: "8px",
          borderBottomWidth: "1px",
          borderBottomColor: "rgba(208, 206, 208, 1)",
          borderBottomStyle: "solid",
          display: "flex",
          alignItems: "center",
        }}
        {...cityBox}
      >
        <Icon
          color={"inherit"}
          fontSize={"inherit"}
          icon={HiLocationMarker}
          css={{ fontSize: "22px" }}
        />
        <Typography
          variant={"overline"}
          text={"Ville"}
          gutterBottom={false}
          noWrap={false}
          css={{ flexGrow: "1" }}
        />
        <Typography
          variant={"body1"}
          text={"<b>Mon titre ici</b>"}
          gutterBottom={false}
          noWrap={false}
          {...city}
        />
      </Box>
      <Box
        tag={"div"}
        css={{
          padding: "8px",
          gap: "8px",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Icon
          color={"inherit"}
          fontSize={"inherit"}
          icon={MdInfo}
          css={{ fontSize: "22px" }}
        />
        <Typography
          variant={"overline"}
          text={"Statut"}
          gutterBottom={false}
          noWrap={false}
          css={{ flexGrow: "1" }}
        />
        <Box tag={"div"} css={{ minWidth: "160px" }} {...status} />
      </Box>
      <Box tag={"div"} css={{ marginTop: "8px" }} {...properties} />
    </Box>
  );
};

export default LeadPreview;
// #endregion
