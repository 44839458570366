import { useCallback, useMemo } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  collectionListSelector,
  collectionQuery,
} from "@/store/collection-list";
import { Pagination } from "@mui/material";
import { CollectionListProps } from ".";

const CollectionPagination: React.FC<
  Pick<CollectionListProps, "collection" | "id" | "defaultFilters">
> = (props) => {
  const { collection, id, defaultFilters } = props;

  const [query, setQuery] = useRecoilState(collectionQuery({ collection, id }));
  const res = useRecoilValue(
    collectionListSelector({ collection, id, defaultFilters })
  );

  const handlePaginate = useCallback(
    (_event: any, page: number) => {
      setQuery((q) => ({ ...q, pagination: { ...q.pagination, page } }));
    },
    [setQuery]
  );

  const count = useMemo(
    () => res?.meta.pagination.pageCount || 0,
    [res?.meta.pagination.pageCount]
  );

  return (
    <Pagination
      color="secondary"
      hidden={count <= 1}
      count={count}
      page={query.pagination.page}
      onChange={handlePaginate}
    />
  );
};

export default CollectionPagination;
