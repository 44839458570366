/** Build with repleek */
// #region Import
import React from "react";

import { Box, BoxProps, Typography, TypographyProps } from "@repleek/mui";
// #endregion

// #region Type Definition
type BriefFieldProps = Partial<BoxProps> & {
  label: TypographyProps & {},
  value: Partial<BoxProps>,
};
// #endregion

// #region BriefFieldComponent
const BriefField: React.FC<BriefFieldProps> = (props) => {
  const { label, value, ...rootProps } = props;

  return (
    <Box
      tag={"div"}
      css={{
        padding: "12px",
        boxShadow:
          "rgba(0, 0, 0, 0.02) 0px 1px 3px 0px , rgba(27, 31, 35, 0.15) 0px 0px 0px 1px",
        marginBottom: "16px",
        borderRadius: "8px",
      }}
      {...rootProps}
    >
      <Typography
        variant={"h4"}
        text={"Mon titre ici"}
        gutterBottom={false}
        noWrap={false}
        css={{
          fontSize: "14px",
          marginBottom: "8px",
          textDecoration: "underline",
        }}
        {...label}
      />
      <Box tag={"div"} {...value} />
    </Box>
  );
};

export default BriefField;
// #endregion
