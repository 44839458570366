import React, { useCallback, useMemo, useState } from "react";
import CollectionList, {
  CollectionListProps,
} from "@/components/layout/collections";
import { UserType, userTypeOptions } from "@/store/user";
import { Workspace } from "@/modules/strapi-sdk/lib/interfaces";
import { IoMdPaperPlane } from "react-icons/io";
import UserModal from "@/components/features/user/UserModal";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSetRecoilState } from "recoil";
import { collectionQuery } from "@/store/collection-list";
import { EmailModal } from "@/components/features/notificationCenter";
import { useStrapi } from "@/providers/StrapiProvider";

type Props = {
  workspace?: Workspace;
  type?: UserType;
  id: `${UserType | "all" | "workspace"}-users` | "workspace-members";
  headerContent?: React.ReactNode;
};

const Users: React.FC<Props> = (props) => {
  const { workspace, id, headerContent, type } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [strapi, { loading }] = useStrapi();

  const setQuery = useSetRecoilState(
    collectionQuery({ collection: "me/users", id })
  );

  const [notification, setNotification] = useState<
    { id: number; email?: string; resetToken?: string } | undefined
  >(undefined);

  const userId = useMemo(() => {
    const action = searchParams.get("action");
    if (!action) return false;
    if (action === "new-user") return true;
    if (parseInt(action) > 0) return parseInt(action);
    return false;
  }, [searchParams]);

  const defaultFilters = useMemo(() => {
    const filters: { workspaces?: number[]; type?: UserType[] } = {};
    if (workspace?.id) filters.workspaces = [workspace.id];

    switch (id) {
      case "workspace-members":
        filters.type = ["admin", "cm", "commercial"];
        break;
      case "workspace-users":
      case "customer-users":
        filters.type = ["customer"];
        break;
      case "admin-users":
        filters.type = ["admin"];
        break;
      case "cm-users":
        filters.type = ["cm"];
        break;
      case "commercial-users":
        filters.type = ["commercial"];
        break;
    }
    return filters;
  }, [id, workspace]);

  const filters = useMemo<CollectionListProps["filters"]>(() => {
    switch (id) {
      case "workspace-users":
      case "customer-users":
      case "admin-users":
      case "cm-users":
      case "commercial-users":
      case "all-users":
        return [];

      case "workspace-members":
        return [
          {
            label: "Role",
            name: "type",
            type: "tag",
            options: userTypeOptions.filter(({ value }) =>
              ["admin", "cm", "commercial"].includes(value)
            ),
          },
        ];

      default:
        return [
          {
            label: "Role",
            name: "type",
            type: "tag",
            options: userTypeOptions,
          },
        ];
    }
  }, [id]);

  const onClose = useCallback(() => {
    searchParams.delete("action");
    setSearchParams(searchParams);

    navigate({
      pathname: location.pathname,
      search: searchParams.toString() ? `?${searchParams.toString()}` : "",
    });
  }, [location.pathname, navigate, searchParams, setSearchParams]);

  const refresh = useCallback(() => {
    setQuery((q) => ({ ...q }));
  }, [setQuery]);

  const onSendNotification = useCallback(
    async (id: number) => {
      if (!id) return undefined;
      setNotification({ id });
      const user = await strapi?.findOne("me/users", id);
      if (user) {
        const { email } = user;
        setNotification({ id, email });
        const resetToken = await strapi?.get("/auth/:email/reset-token", {
          ":email": email,
        });
        if (resetToken) setNotification({ id, email, resetToken });
        else setNotification(undefined);
      }
    },
    [strapi]
  );

  const onCreate = useCallback(() => {
    let link = "";
    switch (id) {
      case "workspace-members":
      case "workspace-users":
        link = `/workspaces/${workspace?.id}/users?action=new-user`;
        break;

      default:
        if (type) link = `/users/${type}?action=new-user`;
        else link = `/users?action=new-user`;
        break;
    }
    navigate?.(link);
  }, [id, navigate, type, workspace?.id]);

  const onOpen = useCallback(
    (id: number) => {
      let link = "";
      switch (props.id) {
        case "workspace-members":
        case "workspace-users":
          link = `/workspaces/${workspace?.id}/users?action=${id}`;
          break;

        default:
          if (type) link = `/users/${type}?action=${id}`;
          else link = `/users?action=${id}`;
          break;
      }
      navigate?.(link);
    },
    [navigate, props.id, type, workspace]
  );

  return (
    <>
      <CollectionList
        collection={"me/users"}
        id={id}
        filters={filters}
        headerContent={headerContent}
        defaultFilters={defaultFilters}
        deleteItem
        onCreate={onCreate}
        onOpen={onOpen}
        onUpdate={onSendNotification}
        actions={{
          openBtn: { text: "Modifier" },
          editBtn: { Icon: <IoMdPaperPlane /> },
        }}
      />

      <EmailModal
        open={!!notification}
        loading={loading || !notification?.email}
        models={["resetPassword", "DEFAULT"]}
        content={{
          resetPassword: {
            cta: {
              label: "Réinitialiser mon mot de passe",
              link: `https://dashboard.agence-pistache.fr/reset-password?token=${notification?.resetToken}`,
            },
          },
        }}
        email={notification?.email}
        onClose={() => setNotification(undefined)}
      />

      <UserModal
        open={!!userId}
        id={typeof userId === "number" ? userId : undefined}
        wkId={workspace?.id}
        onClose={onClose}
        onSave={refresh}
      />
    </>
  );
};

export default Users;
