import { I18nLabels } from "@/modules/strapi-sdk/lib/type";
import { strapiInstance } from "@/providers/StrapiProvider";
import { atom, selector } from "recoil";

export const localeState = atom<keyof I18nLabels>({
  key: "i18n-locale",
  default: "fr",
});

export const localesQuery = selector({
  key: "i18n-locales",
  get: async ({ get }) => {
    const locale = get(localeState);
    return await strapiInstance.get("i18n/:locale", {
      ":locale": locale,
    });
  },
});
